
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as edit_45typeHPkYvnY_45XDlXMwzCiXFipkwwhd_79OmVnOpdQMCtJkgMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_93fCg5So9tZbDlqqq4LtlxFs3OMyg6FCRjinyPXN_uMfUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as createKx_45LVPluNwaQ6n9kGt_45CXDX87XfjKSaIwUaZn2jiKeoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as indexY8DTiGd1UVV_45ibuQ4lCYRGB4YesIRbbjNbx2TrpCWJMMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as enginevhzp_45O9M_ROmLaiSOaJWFvgEUcTiv8kYrrzfuByYtGkMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as indexR_45ueWrijtTL0m_454EgKrKu_45AtbiAQI6waocwcqEmZOUQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue?macro=true";
import { default as claim_45types_45historyvb7CpzsrrMgjYUcwSBLdarTeO_45dTq_WgdWwlhXeeaQAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/claim-types-history.vue?macro=true";
import { default as enginesKDpJGpDB_i_45xg3Ohe_45bg6UsYxtvJaFHkSqkglQfeVgwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexG_GNsaA0HseShhMgiOpgZZMIZKrRhD8BMtxAxI5O1_45MMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as indexmX3Jg2hxm4pDXD3l0gyQrB4Y0PJNsrVwICdA95z5WPwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93npaKZWL8jSlQn2qRNMLV8Ne_4OlhkvMDbQJgXpCCVkYMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as indexFhWAQQurxKpvsku7vyzT5gGRhFqdv2nSAFhquzFQv8wMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editor7oZMybxfdngmo1cUB9qv0s_NHH5QJBHtPkmU9jJGra8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexnCaSTlBHszZkdnqi7Js2knf_45LsGgQm3nwsw0GEC4C74Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as editpE1hL49VLoyIXHtlT5enAZcqnuc_45ud6dG6hudimXubMMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as add_aRUZ2VyV_zGTzEIuSjK6ultDGRet9UbOxl2x7fZsEIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as create7VNawQN3RuJ_45goL183kprtxeNPxSdS8M11t_cDYd7PQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexv0G5FHmWYmnUsVRXi6Zr_456BC1IEX8WkV7YzhZYFPpswMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93_45FpIDARhegBKxxUJQ6YOgv4IOsDWPVg58lHbvxAh4HAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as createwwxFy72M9ao05DPYveD5J2clKp1gUWlvbX9tZ3FFKpsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as index9HaaSfwZ0LWWOtGO1mqhVxz3Th3tFWhVZMgnJBI7BW8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerAl3_45si1qgR3tJpqNXPyhvxYBn1nYNPf5XSTuHwtg6NwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as regionaNjCwlN9cw5moTd5lvOx6UrvdgtGdVnQvjJJ8YAAa1MMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documentTt6xSSi2KOUGtB02RPMl0sQdeUnxXwK2RF_45mp6WD62gMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_451vYcMI4JPk6Ls_45vRoGDyDZr180c_a8HSg2apOI5gQbvEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_4521dtaAD2u_K15G7YdlOeVPiwmzmcq0fGgmAqbm8t9WkoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_93XiHQCSULYwa1U5QgTwITWxngbOiUqqNzAtY2v4W9g4kMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createWbZ9LFbviU2tZ3m8mgMaMDdyUt_M6ky2wmdj_B1u74oMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as indexrlXMCCv43jdbVvrSAtKyXOPKt9Ci2MZ_45jeIefQ1VESEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicJe3spevs2QuZ3yxJ6sgGRsT6TOleTpQJRQqULmCE55QMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "edit-type",
    path: "/edit-type",
    meta: edit_45typeHPkYvnY_45XDlXMwzCiXFipkwwhd_79OmVnOpdQMCtJkgMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue")
  },
  {
    name: enginevhzp_45O9M_ROmLaiSOaJWFvgEUcTiv8kYrrzfuByYtGkMeta?.name,
    path: "/engine",
    meta: enginevhzp_45O9M_ROmLaiSOaJWFvgEUcTiv8kYrrzfuByYtGkMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue"),
    children: [
  {
    name: "engine-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue")
  },
  {
    name: "engine-create",
    path: "create",
    meta: createKx_45LVPluNwaQ6n9kGt_45CXDX87XfjKSaIwUaZn2jiKeoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue")
  },
  {
    name: "engine",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue")
  },
  {
    name: policy_45editor7oZMybxfdngmo1cUB9qv0s_NHH5QJBHtPkmU9jJGra8Meta?.name,
    path: "/policy-editor",
    meta: policy_45editor7oZMybxfdngmo1cUB9qv0s_NHH5QJBHtPkmU9jJGra8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue"),
    children: [
  {
    name: _91id_93npaKZWL8jSlQn2qRNMLV8Ne_4OlhkvMDbQJgXpCCVkYMeta?.name,
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue"),
    children: [
  {
    name: "policy-editor-id-claim-types-history",
    path: "claim-types-history",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/claim-types-history.vue")
  },
  {
    name: "policy-editor-id-engines",
    path: "engines",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue")
  },
  {
    name: "policy-editor-id",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue")
  },
  {
    name: "policy-editor-id-reference-document",
    path: "reference-document",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue")
  }
]
  },
  {
    name: "policy-editor",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue")
  }
]
  },
  {
    name: "policy-id",
    path: "/policy/:id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue")
  },
  {
    name: "policy-id-reference-document-docId-edit",
    path: "/policy/:id()/reference-document/:docId()/edit",
    meta: editpE1hL49VLoyIXHtlT5enAZcqnuc_45ud6dG6hudimXubMMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue")
  },
  {
    name: "policy-id-reference-document-add",
    path: "/policy/:id()/reference-document/add",
    meta: add_aRUZ2VyV_zGTzEIuSjK6ultDGRet9UbOxl2x7fZsEIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue")
  },
  {
    name: "policy-create",
    path: "/policy/create",
    meta: create7VNawQN3RuJ_45goL183kprtxeNPxSdS8M11t_cDYd7PQMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexv0G5FHmWYmnUsVRXi6Zr_456BC1IEX8WkV7YzhZYFPpswMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue")
  },
  {
    name: providerAl3_45si1qgR3tJpqNXPyhvxYBn1nYNPf5XSTuHwtg6NwMeta?.name,
    path: "/provider",
    meta: providerAl3_45si1qgR3tJpqNXPyhvxYBn1nYNPf5XSTuHwtg6NwMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue"),
    children: [
  {
    name: "provider-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue")
  },
  {
    name: "provider-create",
    path: "create",
    meta: createwwxFy72M9ao05DPYveD5J2clKp1gUWlvbX9tZ3FFKpsMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue")
  },
  {
    name: "provider",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue")
  }
]
  },
  {
    name: "region",
    path: "/region",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue")
  },
  {
    name: "source-document",
    path: "/source-document",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue")
  },
  {
    name: "source-type-level-1",
    path: "/source-type-level-1",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue")
  },
  {
    name: "source-type-level-2",
    path: "/source-type-level-2",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue")
  },
  {
    name: topicJe3spevs2QuZ3yxJ6sgGRsT6TOleTpQJRQqULmCE55QMeta?.name,
    path: "/topic",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue"),
    children: [
  {
    name: "topic-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue")
  },
  {
    name: "topic-create",
    path: "create",
    meta: createWbZ9LFbviU2tZ3m8mgMaMDdyUt_M6ky2wmdj_B1u74oMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue")
  },
  {
    name: "topic",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue")
  }
]
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]